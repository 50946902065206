import React from "react"
import Layout from "../components/Layout"
import TextHighlight from "../components/TextHighlight"
import Space from "../components/Space"
import { Link, navigate } from "gatsby"
import styled from "styled-components"
import Seo from "../components/Seo"
import { SiteGoal } from "../components/HeaderSection"
import ArrowUp from "../icons/arrow-up.svg"
import { Arrow, StyledButton } from "../components/Back"
import { SubHeader } from "../components/Subheader"
import { BiggerBody, MindsetPT, MindsetPTBackground } from "./index"
import StartImg from "../img/backstory/newton-pendel-start.svg"
import AfterImg from "../img/backstory/knewton-pendel-after.svg"
import { CATSubheadline, CATUppercase, PTButton, PTHeadline } from "../components/CTASection"
import Button from "../components/Button"
import { BackArrow, StyledBackButton } from "../components/BackSideEnd"
import { Backlink } from "./resources"

export const StartTextSection = styled.section`
  grid-column: 7 / span 6;
  grid-row: 7;
  padding-top: 15.5rem;
  @media screen and (max-width: 90rem) {
    padding-top: 14rem;
    grid-column: 5 / span 3;
  }
  @media screen and (max-width: 63rem) {
    grid-column: 2 / span 6;
    padding-top: 2rem;
    grid-row: 8;
  }
`
export const StartPicSection = styled.h3`
  margin-top: -8rem;
  grid-column: 2 / span 4;
  grid-row: 7;
  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 3;
    margin: -3rem 6vw 0 6vw;
  }
  @media screen and (max-width: 63rem) {
    grid-column: 3 / span 4;
    grid-row: 7;
    margin-top: -4rem;
  }
`
export const AfterTextSection = styled.section`
  grid-column: 2 / span 6;
  grid-row: 10;
  padding-top: 15.5rem;
  @media screen and (max-width: 90rem) {
    padding-top: 14rem;
    grid-column: 2 / span 3;
  }
  @media screen and (max-width: 63rem) {
    grid-column: 2 / span 6;
    padding-top: 2rem;
    grid-row: 11;
  }
`
export const AfterPicSection = styled.h3`
  margin-top: -8rem;
  grid-column: 8 / span 5;
  grid-row: 10;
  padding-left: 3rem;
  @media screen and (max-width: 90rem) {
    grid-column: 5 / span 3;
    margin: -3rem 3vw 0 3vw;
    padding-left: 0;

  }
  @media screen and (max-width: 63rem) {
    grid-column: 3 / span 4;
    grid-row: 10;
    margin-top: -4rem;
  }
`
const Hashtag = styled.h5`
  padding-top: 2rem;
  @media screen and (max-width: 90rem) {
    padding-top: 1rem;
  }
`

const BackLinkSection = styled.div`
  cursor: pointer;
  align-self: center;
  grid-column: 2 / span 7;
  grid-row: 28;
  margin-bottom: 1.2rem;

  @media screen and (max-width: 90rem) {
    grid-column: 3 / span 5;
    grid-row: 28;
  }
  @media screen and (max-width: 63rem) {
    grid-column: 4 / span 4;
    grid-row: 33;
    padding-left: 0.5rem;
  }
`

export default function Backstory() {
  return (
    <Layout>
      <Seo
        title={"Backstory – A look behind the scenes"}
        description={
          "Make Web Sustainable informs about sustainable web design and creates a sustainable mindset/toolset with the goal to reduce the CO2 emission of websites"
        }
        keywords={[
          "Make Web Sustainable",
          "Sustainable Web Design",
          "Home",
          "Introduction",
          "CO2 Emission",
        ]}
      />
      <StyledButton onClick={() => navigate(-1)}>
        <Arrow>
          <img src={ArrowUp} alt={"Arrow up"} />
        </Arrow>
      </StyledButton>
      <SubHeader>
        <div>
          <h1>
            <TextHighlight colorValue={"green"}>Backstory</TextHighlight>
            <br />
            Make Web
            <br />
            Sustain&shy;able.
          </h1>
          <h2>
            A look{" "}
            <TextHighlight colorValue={"green"}>
              behind-the-scenes
            </TextHighlight>
            .
          </h2>
        </div>
      </SubHeader>
      <Space big four />
      <SiteGoal>
        <h2>
          Telling you how this website started and taking you behind-the-scenes
          of my sustainability journey so far.
        </h2>
      </SiteGoal>
      <StartPicSection>
        <img src={StartImg} width="100%" height="auto" alt={"Newtonian pendulum with one ball green and about to hit the other white ones"}/>
      </StartPicSection>
      <StartTextSection>
        <h3>
          It all <TextHighlight colorValue={"green"}>started</TextHighlight>{" "}
          with...
        </h3>
        <h4>
          ... a 23 years old
          digital media design Student from Germany (me^^) learning that there
          is a connection between the way websites are designed and built and
          their CO2 emissions.
        </h4>
        <BiggerBody>
          At that time I was in my last year of studies at the University of
          Applied Sciences Ravensburg-Weingarten (RWU). I also worked as a
          student in the web department of an agency where i was responsible
          for the concept and design of websites, small programming tasks and
          the maintenance of websites.
          <br /> <br />
          This means that my decisions have determined the size of the website
          and thus its CO2 emissions without knowing that there is a
          correlation between the size of the website and its CO2 consumption.
        </BiggerBody>
        <Hashtag>
          <TextHighlight colorValue={"green"}>
            #BeAwareOfYourInfluence
          </TextHighlight>
        </Hashtag>
      </StartTextSection>
      <Space big doubleeight />
      <AfterPicSection>
        <img src={AfterImg} width="100%" height="auto" alt={"Newtonian pendulum with green balls swinging"}/>
      </AfterPicSection>
      <AfterTextSection>
        <h3>
          After the
          <br />
          <TextHighlight colorValue={"green"}>wake-up</TextHighlight> call...
        </h3>
        <h4>
          ... it was clear to
          me that I had found my dedicated topic. I could combine my two passions
          of design and sustainability.
        </h4>
        <BiggerBody>
          As part of my bachelor thesis, I was able to delve into the topic,
          which meant I started consuming all the information I could find on
          the subject. After a while the information became repetitive and their
          referenced sources repeated themselves . By talking to sustainability
          experts and people working in the field of sustainable web design, I
          was able to deepen my knowledge and learn from their experiences.
          <br /> <br />
          This website you are on was designed and developed as part of my
          bachelor thesis. With "make web sustainable" I want to educate people
          who are interested in the topic of sustainable web design and create a
          sustainable mind & toolset for stakeholders in the development process
          of a website to reduce the CO2 emission of websites.
        </BiggerBody>
        <Hashtag>
          <TextHighlight colorValue={"green"}>
            #MakeWebSustainable
          </TextHighlight>
        </Hashtag>
      </AfterTextSection>
      <Space big eleven />
      <MindsetPTBackground />
      <MindsetPT>
        <CATUppercase>
          <TextHighlight colorValue={"blue"}>Up Next</TextHighlight>
        </CATUppercase>
        <PTHeadline>
          You want to see a{" "}
          <TextHighlight colorValue={"blue"}>solution proposal</TextHighlight>?
        </PTHeadline>
        <CATSubheadline>
          Take a look at a sustainable mindset & process with four core design
          principles.
        </CATSubheadline>
        <PTButton>
          <Link to="/mindset">
            <Button catPrimaryBlue alignLeft>
              Explore mindset
            </Button>
          </Link>
        </PTButton>
      </MindsetPT>
      <Space twentyseven />
      <StyledBackButton onClick={() => navigate("/")}>
        <BackArrow>
          <img src={ArrowUp} alt={"Arrow up"} />
        </BackArrow>
      </StyledBackButton>
      <BackLinkSection onClick={() => navigate("/")}>
        <Backlink>Back to Intro</Backlink>
      </BackLinkSection>
    </Layout>
  )
}
